'use client'; // Error components must be Client Components

import React, { useEffect } from 'react';
import { Button } from '../_components/Button';
import { Link } from '@/navigation';
import { useTranslations } from 'next-intl';
import { logToPayload } from '../_utilities/log-to-payload';
import { usePathname } from 'next/navigation';

export default function Error({
	error,
	reset,
}: {
	error: Error & { digest?: string };
	reset: () => void;
}) {
	const pathname = usePathname();
	const t = useTranslations();

	useEffect(() => {
		logToPayload(error.message, { pathname, ...error });
	}, []);

	return (
		<div className="pt-header-height mx-auto flex h-screen items-center justify-center">
			<div className="flex max-w-[800px] flex-col justify-center space-y-10 p-4">
				<h2 className="m-0 text-center">{t('something-went-wrong')}</h2>
				{error?.message && (
					<p className="text-muted-navy max-h-24 overflow-hidden text-center leading-8 text-clip">
						{t('we-encountered-issue')} {error.message}
					</p>
				)}
				<div className="flex justify-center space-x-2">
					<Button
						size="small"
						intent="secondary"
						onClick={
							// Attempt to recover by trying to re-render the segment
							() => reset()
						}
					>
						{t('try-again')}
					</Button>

					<Link href="/courses">
						<Button size="small" variant="outlined">
							{t('go-to-home-page')}
						</Button>
					</Link>
				</div>
				<p className="text-muted-navy text-center text-sm">
					{t('sorry-for-the-inconvenience', { email: 'support@ebs.ee' })}
				</p>
			</div>
		</div>
	);
}
